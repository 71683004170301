/* You can add global styles to this file, and also import other style files */
//@import "../node_modules/bootstrap/scss/bootstrap.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

//@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1440px,
        xl: 1280px
) !default;

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap.scss';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
